import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { DatePicker } from '@src/components/DatePicker'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import SelectMerchantsDialog from '@src/components/SelectMerchantsDialog'
import {
  cantSelectDate,
  createNewPersonalLoanWorksheetDto,
  getPersonalFundingDto,
  isVariableInterest,
  provinceSupportsInsurance,
  updatePersonalLoanWorksheet,
} from '@src/data/worksheet-selectors'
import { FormatCurrency, formatDate, normalizeNumber } from '@src/services/Formatter'
import { Constants, EPaymentPlan, EProvince } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { UserDtoFromJwt } from '@src/types/User'
import { EditPersonalLoanWorksheetDto } from '@src/types/WorksheetSchema'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import { Add as AddIcon } from '@mui/icons-material'
import {
  useGetPersonalFunding,
  usePostWorksheet,
  usePutWorksheet,
  useGetPossibleTerms,
} from '@src/data/api/worksheet-api/worksheet-api'
import { ObjectSchema } from 'yup'
import { useComputeFirstPaymentDateOptions } from '../../ViewCreditApplicationPage/worksheet-hooks'
import WorksheetFundingSummary from './WorksheetFundingSummary'

type Props = {
  listHolidays: Date[]
  merchant: Merchant
  creditApplication: CreditApplication
  user: UserDtoFromJwt
  merchantsListFromParentId: Merchant[]
  merchantPayments: { label: string; value: string; id: string }[]
  merchantList: Merchant[]
  setMerchantList: (newMerchants: Merchant[]) => void
  validator: ObjectSchema<EditPersonalLoanWorksheetDto>
}
const EditWorksheet = ({
  listHolidays,
  merchant,
  creditApplication,
  user,
  merchantsListFromParentId,
  merchantPayments,
  merchantList,
  setMerchantList,
  validator,
}: Props) => {
  const {
    control,
    register,
    trigger,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EditPersonalLoanWorksheetDto>({
    mode: 'onBlur',
    defaultValues: (creditApplication?.worksheet ?? validator.getDefault()) as unknown as EditPersonalLoanWorksheetDto,
    resolver: yupResolver(validator),
  })
  const [postWorksheet, isPosting, isPostError, postError] = usePostWorksheet()
  const [putWorksheet, isPuting, isPutError, updateError] = usePutWorksheet()
  const [openUserDialog, setOpenUserDialog] = React.useState<boolean>(false)
  const [openInsuranceInfoBox, setOpenInsuranceInfoBox] = useState(false)
  const deliveryOn = watch('deliveryOn')
  const amountRequested = normalizeNumber(watch('amountRequested')?.toString()) ?? 0
  const paymentFrequency = watch('paymentFrequency') ?? 'monthly'
  const term = watch('term')
  const firstPaymentOn = watch('firstPaymentOn')
  const paymentPlanId = watch('paymentPlanId')
  const includeInsurance = watch('includeInsurance')
  const firstPaymentDateOptions = useComputeFirstPaymentDateOptions(deliveryOn, listHolidays)
  const hasVariableInterest = isVariableInterest(paymentPlanId)
  const doesProvinceSupportInsurance = provinceSupportsInsurance(creditApplication?.applicant.currentAddress.stateIso)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dto = useMemo(() => {
    return getPersonalFundingDto(
      amountRequested,
      paymentFrequency,
      term,
      deliveryOn,
      new Date(firstPaymentOn),
      includeInsurance,
      creditApplication?.applicant.currentAddress.stateIso,
      paymentPlanId,
      creditApplication?.finalCreditDecision.interestRate,
    )
  }, [
    amountRequested,
    creditApplication?.applicant.currentAddress.stateIso,
    creditApplication?.finalCreditDecision.interestRate,
    deliveryOn,
    firstPaymentOn,
    includeInsurance,
    paymentFrequency,
    paymentPlanId,
    term,
  ])
  const computeMonthTermFrequency = useMemo(
    () => ({
      merchantId: creditApplication?.merchantId,
      paymentPlanId,
      interestRate: creditApplication?.finalCreditDecision.interestRate,
      hasCoapplicant: creditApplication.coApplicant !== null,
      stateIso: creditApplication?.applicant.currentAddress.stateIso as EProvince,
      paymentFrequency,
      deliveryOn,
      firstPaymentOn: new Date(firstPaymentOn),
      maxPmtAmount: creditApplication.finalCreditDecision.maxPmtAmount,
      amountRequested,
      includeInsurance,
      include84Months: amountRequested >= Constants.LargeLoanAmount,
    }),
    [
      creditApplication?.merchantId,
      paymentPlanId,
      creditApplication?.finalCreditDecision.interestRate,
      creditApplication.coApplicant,
      creditApplication?.applicant.currentAddress.stateIso,
      paymentFrequency,
      deliveryOn,
      firstPaymentOn,
      creditApplication.finalCreditDecision.maxPmtAmount,
      amountRequested,
      includeInsurance,
    ],
  )
  const [possibleTerms, isLoadingTerms] = useGetPossibleTerms(computeMonthTermFrequency)

  const [computedPersonalLoanFunding, isComputing] = useGetPersonalFunding(creditApplication?.id, dto)

  const onClosedMerchantDialog = useCallback(() => {
    setOpenUserDialog(false)
  }, [])

  const resetPayments = useCallback(() => {
    merchantList.forEach((_merchant, index) => {
      setValue(`merchantPayments.${index}.amount`, Number.NaN)
    })
  }, [merchantList, setValue])

  useEffect(() => {
    if (amountRequested && amountRequested < Constants.LargeLoanAmount)
      setValue('term', creditApplication?.finalCreditDecision.maxTermDuration ?? 72)
    else if (!term) setValue('term', Constants.LongTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountRequested, creditApplication?.finalCreditDecision.maxTermDuration, setValue])

  const onMerchantsSelected = useCallback(
    (newMerchants: Merchant[]) => {
      setOpenUserDialog(false)
      resetPayments()
      setMerchantList(newMerchants)
    },
    [resetPayments, setMerchantList],
  )

  const shouldDisableDate = useCallback(
    (date: Date) => {
      return cantSelectDate(date, listHolidays)
    },
    [listHolidays],
  )

  const navigateToView = (financingProgramId: string, id: string) => {
    navigate(`/credits/${financingProgramId}/${id}`)
  }

  const onSubmit = async (formData: EditPersonalLoanWorksheetDto) => {
    merchantList.forEach((merch, index) => {
      formData.merchantPayments[index].merchantId = merch.id
      formData.merchantPayments[index].paymentMethod = merchant.defaultPaymentMethod
    })
    if (creditApplication.worksheet == null) {
      await postWorksheet({ worksheet: createNewPersonalLoanWorksheetDto(creditApplication.id, formData) }).then(() => {
        navigateToView(creditApplication.financingProgramId, creditApplication.id)
      })
    } else {
      await putWorksheet({
        worksheet: updatePersonalLoanWorksheet(formData, creditApplication) as unknown as EditPersonalLoanWorksheetDto,
      }).then(() => {
        navigateToView(creditApplication.financingProgramId, creditApplication.id)
      })
    }
  }

  useEffect(() => {
    if (!user.isBannerUser && amountRequested) setValue(`merchantPayments.0.amount`, amountRequested)
  }, [amountRequested, setValue, user.isBannerUser])

  useEffect(() => {
    if (merchant?.paymentPlans) {
      const cTerm = merchant.paymentPlans.find((plan) => plan.id === paymentPlanId)
      if (cTerm) {
        setValue('term', cTerm.loanTerm, { shouldValidate: true })
      }
    }
  }, [merchant.paymentPlans, paymentPlanId, setValue])

  const isAPRTooHigh = useMemo(() => {
    const address = creditApplication?.applicant.currentAddress
    if (!address || !computedPersonalLoanFunding) return false

    const { effectiveRate } = computedPersonalLoanFunding
    const { stateIso } = address

    const aprThresholds: Record<string, number> = {
      [EProvince.newfoundland]: 27,
    }

    const threshold = aprThresholds[stateIso!]

    return threshold !== undefined && effectiveRate >= threshold
  }, [computedPersonalLoanFunding, creditApplication?.applicant.currentAddress])

  useEffect(() => {
    const firstDate = formatDate(firstPaymentDateOptions?.[0]?.toISOString())
    setValue('firstPaymentOn', firstDate, { shouldValidate: true })
  }, [firstPaymentDateOptions, setValue])

  return (
    <div>
      {(postError || updateError) && (
        <Paper>
          <Alert severity="error">
            <Typography>{postError?.response?.data?.message || updateError?.response?.data?.message}</Typography>
          </Alert>
        </Paper>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Grid container marginTop={5}>
            <Grid container marginBottom={5}>
              <Grid item xs={8}>
                <Typography variant="h4">{t('worksheet.worksheet')}</Typography>
                <Typography variant="h5">
                  {t('worksheet.maxLoanAmount')}:{' '}
                  {FormatCurrency(creditApplication.finalCreditDecision.maxAmountFinanced)}{' '}
                </Typography>
              </Grid>
              <Grid
                container
                sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}
                spacing={2}
              >
                <Grid item xs={1}>
                  <AsyncActionButton fullWidth={false} variant="contained" onClick={() => navigate(-1)}>
                    {t('common.previous')}
                  </AsyncActionButton>
                </Grid>
                <Grid item xs={2}>
                  <AsyncActionButton
                    fullWidth={false}
                    variant="contained"
                    disabled={isAPRTooHigh || isComputing || !computedPersonalLoanFunding || isLoadingTerms}
                    isPending={isPosting || isPuting}
                    isError={isPostError || isPutError}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('worksheet.createContract')}
                  </AsyncActionButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <InputTextField
                    label={t('worksheet.requestedAmount')}
                    fullWidth
                    {...register('amountRequested')}
                    error={errors.amountRequested}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DatePicker
                    name="deliveryOn"
                    control={control}
                    label={t('worksheet.activationDate')}
                    error={errors?.deliveryOn}
                    disablePast
                    minDate={new Date()}
                    shouldDisableDate={shouldDisableDate}
                    maxDate={creditApplication?.expiresOn}
                  />
                </Grid>
                <Grid item xs={6} md={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {isLoadingTerms ? (
                    <CircularProgress />
                  ) : (
                    <SelectComponent
                      disabled={hasVariableInterest}
                      valueList={possibleTerms.monthlyTerms?.map((item) => ({
                        label: `${item.term} ${t('worksheet.month')}`,
                        value: item.term,
                      }))}
                      label={t('worksheet.loanTerm') as string}
                      {...register('term')}
                    />
                  )}
                </Grid>

                <Grid item xs={6} md={4}>
                  <SelectComponent
                    valueList={[{ label: 'worksheet.monthly', value: 'monthly' }]}
                    label={t('worksheet.paymentFrequency') as string}
                    {...register('paymentFrequency')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <SelectComponent
                    valueList={firstPaymentDateOptions?.map((item) => ({
                      label: formatDate(item),
                      value: formatDate(item),
                    }))}
                    label={t('worksheet.firstPaymentOn') as string}
                    {...register('firstPaymentOn')}
                    error={errors?.firstPaymentOn}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <SelectComponent
                    valueList={merchantPayments?.map((item) => ({
                      label: item.label.includes(EPaymentPlan.regularDailyInterests)
                        ? t(`ePaymentPlan.${item.label as EPaymentPlan}`)
                        : item.label,
                      value: item.value,
                    }))}
                    label={t('worksheet.paymentPlan') as string}
                    {...register('paymentPlanId')}
                    error={errors?.paymentPlanId}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControlLabel
                    {...register('includeInsurance')}
                    control={<Checkbox defaultChecked={creditApplication?.worksheet?.includeInsurance} />}
                    label={t('worksheet.addInsurance')}
                    disabled={!doesProvinceSupportInsurance}
                  />
                  <Tooltip title={t('worksheet.includeInsurance')}>
                    <IconButton
                      onClick={() => setOpenInsuranceInfoBox((prev) => !prev)}
                      style={{ marginLeft: '-15px' }}
                    >
                      <InfoIcon style={{ fontSize: 'medium' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {openInsuranceInfoBox && (
                  <Box component="div" sx={{ padding: '10px', marginLeft: '15px' }}>
                    <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                      {doesProvinceSupportInsurance
                        ? t('worksheet.insuranceIncitative')
                        : t('worksheet.notAvailableInsurance')}
                    </Typography>
                    {doesProvinceSupportInsurance && (
                      <ul style={{ paddingLeft: '20px' }}>
                        <li>
                          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                            {t('worksheet.lifeInsurance')}
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                            {t('worksheet.accidentalDisability')}
                          </Typography>
                        </li>
                      </ul>
                    )}
                  </Box>
                )}
                <Grid item xs={12}>
                  <Stack sx={{ mb: 2 }} direction="row" alignItems="center">
                    <Typography variant="h5" sx={{ flexGrow: 1 }}>
                      {t('worksheet.paymentBreakdown')}
                    </Typography>
                    {user.isBannerUser && (
                      <>
                        <AsyncActionButton
                          sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                          fullWidth={false}
                          color="secondary"
                          variant="contained"
                          onClick={() => setOpenUserDialog(true)}
                        >
                          {t('worksheet.addMerchantPayment')}
                        </AsyncActionButton>
                        <Tooltip title={t('worksheet.addMerchantPayment')}>
                          <IconButton
                            onClick={() => setOpenUserDialog(true)}
                            color="secondary"
                            sx={{ display: { md: 'none' } }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Stack>

                  <Stack spacing={2}>
                    {merchantList &&
                      merchantList.map((m, index) => {
                        return (
                          <div>
                            <Grid container key={m.id} spacing={1}>
                              <Grid item xs={12} sm={8}>
                                <Typography sx={{ mt: { sm: 2 } }}>{m.name}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <InputTextField
                                  disabled={!user.isBannerUser}
                                  {...register(`merchantPayments.${index}.amount`)}
                                  label={t('worksheet.paymentAmount')}
                                  InputProps={{
                                    endAdornment: '$',
                                  }}
                                  onBlur={() => trigger('amountRequested')}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        )
                      })}
                  </Stack>
                </Grid>
                <Grid item container xs={12} sx={{ display: { md: 'none' } }}>
                  <Grid item xs={12} sm={6}>
                    <AsyncActionButton color="error" onClick={() => navigate(-1)}>
                      {t('common.previous')}
                    </AsyncActionButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AsyncActionButton
                      variant="contained"
                      color="primary"
                      disabled={isAPRTooHigh || isComputing || !computedPersonalLoanFunding}
                      isPending={isPosting || isPuting}
                      isError={isPostError || isPutError}
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t('worksheet.createContract')}
                    </AsyncActionButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <WorksheetFundingSummary
                    creditApplication={creditApplication}
                    amountRequested={amountRequested}
                    hasVariableInterest={hasVariableInterest}
                    firstPaymentOn={firstPaymentOn}
                    merchant={merchant}
                    paymentPlanId={paymentPlanId}
                    provinceSupportsInsurance={doesProvinceSupportInsurance}
                    computedPersonalLoanFunding={computedPersonalLoanFunding}
                    isComputing={isComputing}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </form>
      {user.isBannerUser && (
        <SelectMerchantsDialog
          open={openUserDialog}
          title={t('worksheet.addMerchantPayment')}
          label={t('common.merchant')}
          merchantsTotal={merchantsListFromParentId}
          merchantsSelected={merchantList}
          onConfirm={onMerchantsSelected}
          onCancel={onClosedMerchantDialog}
        />
      )}
    </div>
  )
}

export default EditWorksheet
